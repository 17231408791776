/** @jsxImportSource @emotion/react */
import React, { useLayoutEffect, useState } from 'react';
import { useAuth, useLoginWithRedirect, useAuthActions } from '@frontegg/react';
import { clarityInitialize, pendoInitialize } from '../external-tools';
import { setCurrentTenant, TTenantDetails, getTenant, getCurrentTenant } from '../routes/ManageTenants/tenants';
import { login, logout } from '../utils/fronteggAuth';
import { setCookie, getCookie } from '../utils';


type IProps = {
    children: React.ReactNode;
};

const RootAuthWrapper: React.FC<IProps> = (props) => {
    const { children } = props;
    const { isAuthenticated, isLoading: isUserLoginLoading, user } = useAuth();
    const { switchTenant } = useAuthActions();

    const loginWithRedirect = useLoginWithRedirect();
    const [loggedIn, setLoggedIn] = useState<string | null>(localStorage.getItem("loggedIn"));
    
    useLayoutEffect((): void => {
        (async () => {
            if (!user || isUserLoginLoading || !isAuthenticated) return;

            setCookie('fronteggSessionToken', user.accessToken);


            const psCookieTenant = getCurrentTenant();
            const userTenant: TTenantDetails | null = await getTenant(user);
            if (userTenant === null) {
                logout();
                return;
            }

            if(psCookieTenant?.id !== userTenant.id) {
                //await switchTenant({ tenantId: trueUserTenant.id, callback: () => setCurrentTenant(trueUserTenant) }); return;
                setCurrentTenant(userTenant);
            }


            if (loggedIn === null) {
                await fetch('/api/access/register-login');
                localStorage.setItem("loggedIn", "");
                setLoggedIn("");
            }

            if (process.env.NODE_ENV !== 'development') {
                pendoInitialize(user);
                clarityInitialize();
            }
                
        })();

    //     const setTenant = async () => {
    //         if (user === undefined || user === null) {
    //             logout(false)
    //             return;
    //         }
        
    //         const trueUserTenant: TTenantDetails | null = await getTenant(user);
    //         console.log("tenant", trueUserTenant)
    //         const cookieTenant = getCurrentTenant()
    //         const activeTenantFromJWT = user.tenantId;
    //         if (trueUserTenant === null) {
    //             logout();
    //             return;
    //         } else if (trueUserTenant && (activeTenantFromJWT !== trueUserTenant.id || cookieTenant?.id !== trueUserTenant.id)) {
    //             //await switchTenant({ tenantId: trueUserTenant.id, callback: () => setCurrentTenant(trueUserTenant) });
    //             setCurrentTenant(trueUserTenant);
    //         }
    //         if (loggedIn === null) {
    //             await fetch('/api/access/register-login');
    //             localStorage.setItem("loggedIn", "");
    //             setLoggedIn("");
    //         }
    //     };

    //     if (user && isAuthenticated && !isLoading) {
    //         login(user);
    //         setTenant();
    //         if (process.env.NODE_ENV !== 'development') {
    //             pendoInitialize(user);
    //             clarityInitialize();
    //         }
    //     }
    //}, [isAuthenticated, logout, user, isLoading, loggedIn]);
    }, [user, isAuthenticated, isUserLoginLoading]);

    if (isUserLoginLoading || (loggedIn === null && isAuthenticated !== false)) {

        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
                <div>Loading...</div>
            </div>
        );
    }

    if (!isAuthenticated) {
        loginWithRedirect()
        return (
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
                <div>Loading...</div>
            </div>
        )
    }


    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
}

export default RootAuthWrapper;